import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, addDoc, getDocs, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import { EnvContext } from "../../../context/EnvContext";

const NewProduct = () => {
  const environment = useContext(EnvContext);
  const navigate = useNavigate();
  const db = useFirestore();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [brands, setBrands] = useState([]);
  const [dosingGuides, setDosingGuides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState({
    product_uid: "",
    brand_uid: "",
    product_name: "",
    product_description: "",
    strain_type: "",
    effects: "",
    strength: "",
    product_type: "",
    product_category: "",
    net_weight: 0,
    weight_units: "",
    potency_value: 0,
    dosing_guide: "",
    number_servings: 0,
    serving_size: "",
    dose_per_serving: "",
    perishable: false,
  });
  const [newProductUID, setNewProductUID] = useState(null);

  //
  const effectsOptions = config.effectsOptions;
  const productTypeOptions = config.productTypeOptions;
  const productCategoryOptions = config.productCategoryOptions;
  const weightUnitsOptions = config.weightUnitsOptions;
  const strain_typeOptions = config.strain_typeOptions;

  useEffect(() => {
    fetchBrands();
    fetchDosingGuides();
    getNewProductUID();
  }, [db, collectionPath]);

  //
  const fetchBrands = async () => {
    const productsCollectionRef = collection(db, `${collectionPath}Brands/BrandsList/DataBase`);
    const querySnapshot = await getDocs(productsCollectionRef);
    const brandsArray = [];
    querySnapshot.forEach((doc) => {
      brandsArray.push({ id: doc.id, ...doc.data() });
    });
    setBrands(brandsArray);
  };

  //
  const fetchDosingGuides = async () => {
    const dosingGuidesCollectionRef = collection(db, `${collectionPath}DosingGuides/DosingGuidesList/DataBase`);
    const querySnapshot = await getDocs(dosingGuidesCollectionRef);
    const guidesArray = [];
    querySnapshot.forEach((doc) => {
      guidesArray.push(doc.data().name);
    });
    setDosingGuides(guidesArray);
  };

  //
  const getNewProductUID = async () => {
    const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
    const productsSnapshot = await getDocs(productsCollectionRef);
    let maxUID = 0;
    productsSnapshot.forEach((doc) => {
      const data = doc.data();
      const uid = parseInt(data.product_uid, 10);
      if (uid > maxUID) {
        maxUID = uid;
      }
    });
    setNewProductUID(maxUID + 1);
  };

  //
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);

    try {
      //
      const productsSnapshot = await getDocs(productsCollectionRef);
      const selectedBrand = brands.find((b) => b.brand_uid === parseInt(productData.brand_uid));

      if (!selectedBrand) {
        console.error("Selected brand not found in the list of brands");
        setLoading(false);
        return;
      }

      const newProduct = {
        product_uid: parseInt(newProductUID),
        brand_uid: parseInt(productData.brand_uid),
        brand_name: selectedBrand.brand_name,
        product_name: productData.product_name,
        product_description: productData.product_description || "",
        strain_type: productData.strain_type || "",
        effects: productData.effects || "",
        strength: productData.strength || "",
        product_type: productData.product_type || "",
        product_category: productData.product_category || "",
        net_weight: productData.net_weight || 0,
        weight_units: productData.weight_units || "",
        number_servings: productData.number_servings || 0,
        dosing_guide: productData?.dosing_guide || "",
        serving_size: productData?.serving_size || "",
        dose_per_serving: productData.dose_per_serving || "",
        perishable: productData.perishable,
      };

      // Check for UID uniqueness
      const existingProduct = productsSnapshot.docs.find((doc) => doc.data().product_uid === newProductUID);
      if (existingProduct) {
        throw new Error(`A product with UID ${newProductUID} already exists.`);
      }

      // Add the new product and get the document reference
      const newDocRef = await addDoc(productsCollectionRef, newProduct);

      await updateBrandData(productData.brand_uid, productData.product_category, productData.effects, productData.product_type);
      await updateProductsMetaData(productData.product_category, productData.effects, productData.product_type);
      toast.success("Product added successfully");

      // Redirect to the edit page of the newly created product
      navigate(`/main/products/edit/${newDocRef.id}`);
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Error adding product: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatUID = (uid, totalLength = 8) => {
    return uid ? String(uid).padStart(totalLength, "0") : "";
  };

  const updateBrandData = async (brand_uid, category, effects, productType) => {
    try {
      const brandDocRef = doc(db, `${collectionPath}Brands/BrandsList/DataBase`, brand_uid);
      await updateDoc(brandDocRef, {
        brand_category: arrayUnion(category),
        effects: arrayUnion(effects),
        product_type: arrayUnion(productType),
      });
    } catch (error) {
      console.error("Error updating brand data:", error);
    }
  };

  const updateProductsMetaData = async (category, effects, productType) => {
    try {
      const productsMetaDocRef = doc(db, `${collectionPath}Products/ProductsMeta`);
      await updateDoc(productsMetaDocRef, {
        categories: arrayUnion(category),
        effects: arrayUnion(effects),
        product_type: arrayUnion(productType),
      });
    } catch (error) {
      console.error("Error updating products meta data:", error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <h2>Insert New Product</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Product UID:</label>
            <div>
              <input
                type="text"
                value={`P${formatUID(newProductUID)}`}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    product_uid: e.target.value,
                  })
                }
                style={styles.formInput}
                disabled
              />
            </div>
          </div>

          <div style={styles.formElement}>
            <label style={styles.formLabel}>Brand&#42;:</label>
            <select value={productData.brand_uid} onChange={(e) => setProductData({ ...productData, brand_uid: e.target.value })} style={styles.formSelect}>
              <option key={""} value={""}></option>
              {brands
                .slice() // Create a copy of the array
                .sort((a, b) => a.brand_name.localeCompare(b.brand_name)) // Sort alphabetically
                .map((brand) => (
                  <option key={brand.brand_uid} value={brand.brand_uid}>
                    {brand.brand_name}
                  </option>
                ))}
            </select>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Name&#42;:</label>
            <input
              type="text"
              value={productData.product_name}
              onChange={(e) => setProductData({ ...productData, product_name: e.target.value })}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Description:</label>
            <textarea
              value={productData.product_description}
              onChange={(e) =>
                setProductData({
                  ...productData,
                  product_description: e.target.value,
                })
              }
              style={styles.formInput}
            ></textarea>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Strain Type:</label>
            <select value={productData.strain_type} onChange={(e) => setProductData({ ...productData, strain_type: e.target.value })} style={styles.formSelect}>
              <option value=""></option>
              {strain_typeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Effects:</label>
            <select value={productData.effects} onChange={(e) => setProductData({ ...productData, effects: e.target.value })} style={styles.formSelect}>
              <option value=""></option>
              {effectsOptions.map((effect) => (
                <option key={effect} value={effect}>
                  {effect}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Strength:</label>
            <input
              type="text"
              value={productData.strength}
              onChange={(e) => setProductData({ ...productData, strength: e.target.value })}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Product Type:</label>
            <select
              value={productData.product_type}
              onChange={(e) => setProductData({ ...productData, product_type: e.target.value })}
              style={styles.formSelect}
            >
              <option value=""></option>
              {productTypeOptions.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Product Category:</label>
            <select
              value={productData.product_category}
              onChange={(e) =>
                setProductData({
                  ...productData,
                  product_category: e.target.value,
                })
              }
              style={styles.formSelect}
            >
              <option value=""></option>
              {productCategoryOptions.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Dosing Guide:</label>
            <select
              value={productData.dosing_guide}
              onChange={(e) => setProductData({ ...productData, dosing_guide: e.target.value })}
              style={styles.formSelect}
            >
              <option value=""></option>
              {dosingGuides.map((guide) => (
                <option key={guide} value={guide}>
                  {guide}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ ...styles.formElement, marginRight: 20 }}>
              <label style={styles.formLabel}>Net Weight:</label>
              <input
                type="text"
                value={productData.net_weight}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    net_weight: e.target.value,
                  })
                }
                style={styles.formInput}
              />
            </div>
            <div style={styles.formElement}>
              <label style={styles.formLabel}>Weight Unit:</label>
              <select
                value={productData.weight_units}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    weight_units: e.target.value,
                  })
                }
                style={styles.formSelect}
              >
                <option value=""></option>
                {weightUnitsOptions.map((unit) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Number of Servings:</label>
            <input
              type="text"
              value={productData.number_servings}
              onChange={(e) =>
                setProductData({
                  ...productData,
                  number_servings: e.target.value,
                })
              }
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Serving Size:</label>
            <input
              type="text"
              value={productData.serving_size}
              onChange={(e) => setProductData({ ...productData, serving_size: e.target.value })}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Dose per Serving:</label>
            <input
              type="text"
              value={productData.dose_per_serving}
              onChange={(e) =>
                setProductData({
                  ...productData,
                  dose_per_serving: e.target.value,
                })
              }
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Perishable:</label>
            <input
              type="checkbox"
              checked={productData.perishable}
              onChange={(e) => setProductData({ ...productData, perishable: e.target.checked })}
              style={styles.formInput}
            />
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              backgroundColor: !productData.brand_uid || !productData.product_name || loading ? "grey" : "#478169",
              cursor: !productData.brand_uid || !productData.product_name || loading ? "not-allowed" : "pointer",
            }}
            disabled={!productData.brand_uid || !productData.product_name || loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    width: "100%",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  formSelect: {
    fontSize: 16,
    border: "0.5px solid #ccc",
    borderRadius: 5,
    height: 38,
    backgroundColor: "#fff",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  prefix: {
    position: "absolute",
    justifyContent: "center",
    height: 40,
    alignContent: "center",
    paddingLeft: 15,
    fontWeight: "500",
    color: "gray",
  },
};

export default NewProduct;
