// FeaturedPage.js
import React, { useContext, useEffect, useState } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDocs, collection, doc, setDoc, updateDoc, deleteDoc, writeBatch, addDoc, Timestamp } from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Button, TextField, Typography, Box, Switch } from "@mui/material";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

/**
 * SortableTableRow Component
 * Integrates @dnd-kit sortable functionality with MUI's TableRow.
 */
const SortableTableRow = ({ id, index, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: isDragging ? "#f0f0f0" : "inherit",
    cursor: "grab",
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </TableRow>
  );
};

/**
 * FeaturedPage Component
 * Displays and manages featured products with drag-and-drop reordering.
 */
const FeaturedPage = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const storage = getStorage();

  // Determine the collection path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // State variables
  const [allProducts, setAllProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [customName, setCustomName] = useState("");
  const [customURL, setCustomURL] = useState("");
  // featured collection
  const [featuredCollections, setFeaturedCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [newCollectionName, setNewCollectionName] = useState("");

  // Initialize sensors for @dnd-kit
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Drag activation after moving 5 pixels
      },
    })
  );

  /**
   * Fetch products and featured products from Firestore on component mount
   */
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      if (!selectedCollection) return;

      try {
        // Fetch all featured products
        const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
        const featuredSnapshot = await getDocs(featuredCollectionRef);

        const featuredData = featuredSnapshot.docs
          .map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }))
          .filter((item) => item.featureCollection_id === selectedCollection.id); // Filter by collection ID

        // Sort featured products by 'sorting' field
        featuredData.sort((a, b) => a.sorting - b.sorting);

        setFeaturedProducts(featuredData);
      } catch (error) {
        console.error("Error fetching featured products:", error);
      }
    };

    fetchFeaturedProducts();
  }, [selectedCollection, db, collectionPath]);

  // featured collection

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const collectionRef = collection(db, `${collectionPath}Listings/FeaturedCollection/DataBase`);
        const snapshot = await getDocs(collectionRef);
        const collections = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setFeaturedCollections(collections);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, [db, collectionPath]);

  const handleAddCollection = async () => {
    if (!newCollectionName.trim()) {
      alert("Collection name cannot be empty.");
      return;
    }

    const newCollection = {
      name: newCollectionName,
      creation_time: Timestamp.fromDate(new Date()),
    };

    try {
      const docRef = await addDoc(collection(db, `${collectionPath}Listings/FeaturedCollection/DataBase`), newCollection);
      setFeaturedCollections([...featuredCollections, { id: docRef.id, ...newCollection }]);
      setNewCollectionName(""); // Clear input
    } catch (error) {
      console.error("Error adding collection:", error);
    }
  };

  /**
   * Handle image upload for a featured product
   * @param {string} productId - ID of the product
   * @param {File} file - Image file to upload
   */
  const handleUploadImage = async (productId, file) => {
    if (!file) return;

    try {
      const storageRef = ref(storage, `Featured/${productId}`); // Define storage path
      await uploadBytes(storageRef, file); // Upload image
      const url = await getDownloadURL(storageRef); // Get download URL

      // Update Firestore with new image URL
      const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, productId);
      await updateDoc(docRef, { image_url: url });

      // Update state to reflect the new image
      setFeaturedProducts((prev) => prev.map((product) => (product.id === productId ? { ...product, image_url: url } : product)));

      console.log("Image successfully uploaded and updated");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  /**
   * Toggle the 'enabled' state of a featured product
   * @param {string} productId - ID of the product
   * @param {boolean} enabled - New enabled state
   */
  const handleToggleEnabled = async (productId, enabled) => {
    try {
      const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, productId);
      await updateDoc(docRef, { enabled });

      // Update state
      setFeaturedProducts((prev) => prev.map((product) => (product.id === productId ? { ...product, enabled } : product)));
    } catch (error) {
      console.error("Error toggling enabled state:", error);
    }
  };

  /**
   * Add a custom featured product
   */
  const handleAddCustomFeatured = async () => {
    if (!selectedCollection || !customName.trim() || !customURL.trim()) {
      alert("Please provide both banner name and URL.");
      return;
    }

    const position = featuredProducts.length + 1;
    const newFeaturedProduct = {
      product_name: customName,
      banner_target_url: customURL,
      date_created: Timestamp.fromDate(new Date()),
      sorting: position,
      enabled: false,
      featureCollection_id: selectedCollection.id, // Add collection ID
      featureCollection_name: selectedCollection.name, // Add collection name
    };

    try {
      const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
      const docRef = await addDoc(featuredCollectionRef, newFeaturedProduct); // Add to Firestore
      setFeaturedProducts([...featuredProducts, { id: docRef.id, ...newFeaturedProduct }]); // Update state
      setCustomName("");
      setCustomURL("");
      console.log("Custom product added to featured list");
    } catch (error) {
      console.error("Error adding custom featured product:", error);
    }
  };

  /**
   * Remove a product from the featured list
   * @param {string} productId - ID of the product to remove
   */
  const handleRemoveFromFeatured = async (productId) => {
    try {
      await deleteDoc(doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, productId)); // Remove from Firestore
      setFeaturedProducts((prev) => prev.filter((product) => product.id !== productId)); // Update state
      console.log("Product removed from featured list");
    } catch (error) {
      console.error("Error removing product from featured:", error);
    }
  };

  /**
   * Handle the end of a drag event
   * @param {Object} event - Drag event object
   */
  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = featuredProducts.findIndex((item) => item.id === active.id);
      const newIndex = featuredProducts.findIndex((item) => item.id === over.id);

      // Reorder the featuredProducts array locally
      const reordered = arrayMove(featuredProducts, oldIndex, newIndex);
      setFeaturedProducts(reordered);

      try {
        // Initialize a batch
        const batch = writeBatch(db);

        // Iterate over the reordered list and update the 'sorting' field
        reordered.forEach((product, index) => {
          const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, product.id);
          batch.update(docRef, { sorting: index + 1 }); // Assuming 'sorting' is the field name
        });

        // Commit the batch
        await batch.commit();
        console.log("Sorting order successfully updated in Firestore.");
      } catch (error) {
        console.error("Error updating sorting order:", error);
      }
    }
  };

  return (
    <Box p={2}>
      {!selectedCollection ? (
        <>
          <Typography variant="h4">Featured Collections</Typography>
          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
              <TableBody>
                {featuredCollections.map((collection) => (
                  <TableRow
                    key={collection.id}
                    onClick={() => setSelectedCollection(collection)} // Select collection
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{collection.name}</TableCell>
                    <TableCell>{new Date(collection.creation_time.toDate()).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <TextField placeholder="New Collection Name" value={newCollectionName} onChange={(e) => setNewCollectionName(e.target.value)} fullWidth />
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={handleAddCollection}>
                      Add Collection
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          {/* Header with Back Button and Collection Name */}
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <div
              onClick={() => {
                setSelectedCollection(null);
                setFeaturedProducts([]);
              }}
              style={{ cursor: "pointer" }}
            >
              Back to Collections
            </div>
          </Box>

          <Typography variant="h5" style={{ fontWeight: 200 }}>
            Featured Collection <span style={{ fontWeight: 500 }}>{selectedCollection.name}</span>
          </Typography>
          {/* Featured Products Table with Drag-and-Drop */}
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
            <SortableContext items={featuredProducts.map((item) => item.id)} strategy={verticalListSortingStrategy}>
              <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Position</TableCell>
                      <TableCell>Banner Name</TableCell>
                      <TableCell>Banner Target URL</TableCell> {/* New Column */}
                      <TableCell>Enabled</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {featuredProducts.map((product, index) => (
                      <SortableTableRow key={product.id} id={product.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{product.product_name}</TableCell>
                        <TableCell>{product.banner_target_url}</TableCell> {/* New Cell */}
                        <TableCell>
                          <Switch checked={product.enabled} onChange={(e) => handleToggleEnabled(product.id, e.target.checked)} />
                        </TableCell>
                        <TableCell>
                          {product.image_url ? (
                            <Box display="flex" alignItems="center">
                              <Box
                                sx={{
                                  width: 100,
                                  height: 50,
                                  overflow: "hidden",
                                  borderRadius: "5px",
                                  marginRight: "10px",
                                }}
                              >
                                <img src={product.image_url} alt={product.product_name} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                              </Box>
                              <input type="file" onChange={(e) => handleUploadImage(product.id, e.target.files[0])} accept="image/*" />
                            </Box>
                          ) : (
                            <input type="file" onChange={(e) => handleUploadImage(product.id, e.target.files[0])} accept="image/*" />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Button variant="outlined" color="error" onClick={() => handleRemoveFromFeatured(product.id)}>
                            Remove
                          </Button>
                        </TableCell>
                      </SortableTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </SortableContext>
          </DndContext>

          {/* Add Custom Featured Product Form */}
          <Box mt={4} mb={2}>
            <Typography variant="h6">Add Custom Featured Product</Typography>
            <Box display="flex" alignItems="center" gap={2} mt={1} style={{}}>
              <TextField label="Banner Name" value={customName} onChange={(e) => setCustomName(e.target.value)} variant="outlined" style={{ flex: "1" }} />
              <TextField label="Custom URL" value={customURL} onChange={(e) => setCustomURL(e.target.value)} variant="outlined" style={{ flex: "1" }} />
              <Button variant="outlined" color="primary" onClick={handleAddCustomFeatured} style={{ height: "55px" }}>
                ADD
              </Button>
            </Box>
          </Box>
          {/* Delete Collection Button */}
          {featuredProducts.length === 0 && (
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="error"
                onClick={async () => {
                  try {
                    await deleteDoc(doc(db, `${collectionPath}Listings/FeaturedCollection/DataBase`, selectedCollection.id));
                    setFeaturedCollections((prev) => prev.filter((collection) => collection.id !== selectedCollection.id));
                    setSelectedCollection(null); // Deselect collection
                    console.log("Collection deleted successfully.");
                  } catch (error) {
                    console.error("Error deleting collection:", error);
                  }
                }}
              >
                Delete Collection
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default FeaturedPage;
